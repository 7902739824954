@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;

.about {
  @include center-column;
  margin: 12rem 0 0;
}

.about__content {
  width: 80%;
  @include space-evenly;
  margin: 2rem 0 4rem;
}

.about__image {
  height: 50vh;
  margin: 0 2rem 2rem;
}

.about__text {
  width: 40%;
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 1rem;
  }
}

#contact-link {
  color: $primary-accent-color;
  text-decoration: none;
}

#contact-link:hover {
  @include inline-link-hover;
}

// .about__resume {
//   margin: 6rem;
//   text-align: center;
// }

@media screen and (max-width: 600px) {
  .about__content {
    flex-wrap: wrap;
  }
  .about__image {
    height: 40vh;
  }
  .about__text {
    width: 90%;
    text-align: center;
  }
  // .about__resume {
  //   margin: 3rem 0;
  //   text-align: center;
  // }
}