@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;

.portfolio {
  margin: 12rem 0 0;
  @include center-column;
  .portfolio__projects {
    @include space-evenly;
    width: 100%;
    flex-wrap: wrap;
    margin: 0 4rem 4rem;
    text-align: center;
  }
}