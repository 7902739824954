@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;

.hero {
  height: 100%;
  width: 100%;
  display: flex;
}

.hero__text-container {
  width: 60%;
  @include true-center;
  h1 {
    margin-bottom: 3rem;
  }
  p {
    font-size: 2.5rem;
    letter-spacing: 0.05rem;
  }
}

.hero__text-wrapper {
  display: flex;
  flex-direction: column;
  margin: 8rem;
  .hero__buttons-container {
    display: flex;
    align-items: center;
    margin: 3rem 0;
    button {
      margin-right: 2rem;
    }
    button:hover {
      @include link-hover;
    }
  }
}

.hero__image-container {
  width: 40%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 950px) {
  .hero__text-wrapper {
    margin: 8rem 4rem;
  } 
}

@media screen and (max-width: 780px) {
  .hero__text-container {
    width: 65%;
  }
  .hero__text-wrapper {
    margin: 12rem 2rem;
    text-align: center;
    h2 {
      font-size: 2.8rem;
    }
    p {
      font-size: 2.2rem;
    }
  }
  .hero__buttons-container{
    justify-content: center;
  }
  .hero__image-container {
    width: 35%;
  }
}

@media screen and (max-width: 780px) {
  .hero {
    background-image: url("../assets/philomena.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    @include true-center;
  }
  .hero__image-container {
    display: none;
  }
  .hero__text-container {
    background-color: #F5F5F590;
    height: 100%;
    width: 100%;
  }
  .hero__text-wrapper {
    background-color: #f5f5f5d6;
    width: 100%;
    padding: 1rem;
    // height: 100%;
    @include true-center;
  }
}

@media screen and (max-width: 500px) {
  .hero__text-wrapper {
    margin: 12rem 2rem;
    text-align: center;
    h2 {
      font-size: 2.5rem;
    }
    p {
      font-size: 2rem;
    }
  }
  .hero__buttons-container {
    @include center-column;
    button {
      margin: 1rem;
    }
  }
}