$primary-background-color: #F5F5F5;

$primary-accent-color: #47725d;
// $secondary-accent-color: #A3CBA5;
$secondary-accent-color: #8eba9e;

$primary-text-color: #424242;
$secondary-text-color: #F5F5F5;
$emphasis-text-color: #183C19;

$header-font: 'Glacial Indifference', sans-serif;
$button-font: 'Glacial Indifference', sans-serif;
$body-font: 'Inter', sans-serif;

$box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);