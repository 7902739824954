@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;

html,
body,
.root,
.app,
main,
.main-wrapper {
  margin: 0;
  min-height: 100vh;
}

html {
  font-size: 62.5%;
  background-color: $primary-background-color;
}

nav {
  position: fixed;
  z-index: 1;
}

main {
  display: flex;
  justify-content: center;
  width: 100%;
  .main-wrapper {
    width: 100%;
  }
}

h1 {
  @include header-font;
}

.page-header {
  margin: 3rem;
}

p {
  @include body-font;
  margin: 0;
}

button {
  @include button-text;
  @include button-box;
  transition: transform 0.05s ease-in-out;
}

.primary-button {
  @include button-style-primary;
}

button:hover {
  animation: button-fade 0.5s;
  @include button-hover;
}

a {
  text-decoration: none;
}

a:hover,
button:hover,
.material-symbols-rounded:hover {
  cursor: pointer;
}

.material-symbols-rounded {
  color: $primary-accent-color;
  font-size: 3rem;
}

@keyframes button-fade {
  to {
    @include button-hover;
  }
}

