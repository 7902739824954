@use 'variables' as *;

// LAYOUT

@mixin true-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin space-evenly {
  display: flex;
  justify-content: space-evenly;
}

@mixin space-between {
  display: flex;
  justify-content: space-between;
}

// TYPOGRAPHY
@mixin header-font {
  font-family: $header-font;
  color: $primary-text-color;
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 200;
  letter-spacing: 0.5rem;
}

@mixin body-font {
  font-family: $body-font;
  color: $primary-text-color;
  font-size: 2rem;
  font-weight: 300;
}

// BUTTON DESIGN
@mixin button-text {
  font-family: $button-font;
  font-size: 2rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
}

@mixin button-box {
  width: 16.5rem;
  height: 5.25rem;
  border-radius: 12px;
  border: none;
  box-shadow: $box-shadow;
}

@mixin button-style-primary {
  background-color: $primary-accent-color;
  color: $secondary-text-color;
}

@mixin button-style-secondary {
  color: $primary-accent-color;
}

// HOVER STATES

@mixin button-hover {
  background-color: $secondary-accent-color;
  border: none;
  color: $emphasis-text-color;
}

@mixin link-hover {
  transform: scale(1.1);
  transition: transform 0.05s ease-in-out;
}

@mixin inline-link-hover {
  text-decoration: underline;
  text-decoration-color: $primary-accent-color;
}
