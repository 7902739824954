.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12rem 0 0;
}

.about__content {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  margin: 2rem 0 4rem;
}

.about__image {
  height: 50vh;
  margin: 0 2rem 2rem;
}

.about__text {
  width: 40%;
  display: flex;
  flex-direction: column;
}
.about__text p {
  margin-bottom: 1rem;
}

#contact-link {
  color: #47725d;
  text-decoration: none;
}

#contact-link:hover {
  text-decoration: underline;
  text-decoration-color: #47725d;
}

@media screen and (max-width: 600px) {
  .about__content {
    flex-wrap: wrap;
  }
  .about__image {
    height: 40vh;
  }
  .about__text {
    width: 90%;
    text-align: center;
  }
}
html,
body,
.root,
.app,
main,
.main-wrapper {
  margin: 0;
  min-height: 100vh;
}

html {
  font-size: 62.5%;
  background-color: #F5F5F5;
}

nav {
  position: fixed;
  z-index: 1;
}

main {
  display: flex;
  justify-content: center;
  width: 100%;
}
main .main-wrapper {
  width: 100%;
}

h1 {
  font-family: "Glacial Indifference", sans-serif;
  color: #424242;
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 200;
  letter-spacing: 0.5rem;
}

.page-header {
  margin: 3rem;
}

p {
  font-family: "Inter", sans-serif;
  color: #424242;
  font-size: 2rem;
  font-weight: 300;
  margin: 0;
}

button {
  font-family: "Glacial Indifference", sans-serif;
  font-size: 2rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  width: 16.5rem;
  height: 5.25rem;
  border-radius: 12px;
  border: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: transform 0.05s ease-in-out;
}

.primary-button {
  background-color: #47725d;
  color: #F5F5F5;
}

button:hover {
  animation: button-fade 0.5s;
  background-color: #8eba9e;
  border: none;
  color: #183C19;
}

a {
  text-decoration: none;
}

a:hover,
button:hover,
.material-symbols-rounded:hover {
  cursor: pointer;
}

.material-symbols-rounded {
  color: #47725d;
  font-size: 3rem;
}

@keyframes button-fade {
  to {
    background-color: #8eba9e;
    border: none;
    color: #183C19;
  }
}
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12rem 0 0;
}
.contact .contact__email-container {
  display: flex;
  align-items: center;
  margin: 2rem 0 2em;
}
.contact .contact__email {
  color: #47725d;
  margin-left: 1rem;
}
.contact .contact__email:hover {
  text-decoration: underline;
  text-decoration-color: #47725d;
}
.contact .contact__socials-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 10rem;
  width: 30%;
}
.contact .contact__social {
  width: 5rem;
  transition: transform 0.05s ease-in-out;
}
.contact img:hover {
  transform: scale(1.1);
  transition: transform 0.05s ease-in-out;
}

@media screen and (max-width: 650px) {
  .contact .contact__socials-container {
    width: 60%;
  }
}
@media screen and (max-width: 450px) {
  .contact__email-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.hero {
  height: 100%;
  width: 100%;
  display: flex;
}

.hero__text-container {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero__text-container h1 {
  margin-bottom: 3rem;
}
.hero__text-container p {
  font-size: 2.5rem;
  letter-spacing: 0.05rem;
}

.hero__text-wrapper {
  display: flex;
  flex-direction: column;
  margin: 8rem;
}
.hero__text-wrapper .hero__buttons-container {
  display: flex;
  align-items: center;
  margin: 3rem 0;
}
.hero__text-wrapper .hero__buttons-container button {
  margin-right: 2rem;
}
.hero__text-wrapper .hero__buttons-container button:hover {
  transform: scale(1.1);
  transition: transform 0.05s ease-in-out;
}

.hero__image-container {
  width: 40%;
}
.hero__image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 950px) {
  .hero__text-wrapper {
    margin: 8rem 4rem;
  }
}
@media screen and (max-width: 780px) {
  .hero__text-container {
    width: 65%;
  }
  .hero__text-wrapper {
    margin: 12rem 2rem;
    text-align: center;
  }
  .hero__text-wrapper h2 {
    font-size: 2.8rem;
  }
  .hero__text-wrapper p {
    font-size: 2.2rem;
  }
  .hero__buttons-container {
    justify-content: center;
  }
  .hero__image-container {
    width: 35%;
  }
}
@media screen and (max-width: 780px) {
  .hero {
    background-image: url("../assets/philomena.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero__image-container {
    display: none;
  }
  .hero__text-container {
    background-color: rgba(245, 245, 245, 0.5647058824);
    height: 100%;
    width: 100%;
  }
  .hero__text-wrapper {
    background-color: rgba(245, 245, 245, 0.8392156863);
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 500px) {
  .hero__text-wrapper {
    margin: 12rem 2rem;
    text-align: center;
  }
  .hero__text-wrapper h2 {
    font-size: 2.5rem;
  }
  .hero__text-wrapper p {
    font-size: 2rem;
  }
  .hero__buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .hero__buttons-container button {
    margin: 1rem;
  }
}
.top-nav {
  background-color: #F5F5F5;
  border-top: 5px solid #8eba9e;
  min-width: 100%;
  display: flex;
  justify-content: center;
}

.top-nav__wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#logo {
  margin: 1rem 0 0 2rem;
  width: 29rem;
}

.top-nav__buttons {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 2rem;
}
.top-nav__buttons a {
  font-family: "Glacial Indifference", sans-serif;
  font-size: 2rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  padding-left: 3rem;
  color: #183C19;
  transition: transform 0.05s ease-in-out;
}
.top-nav__buttons a,
.top-nav__buttons a:active {
  text-decoration: none;
}
.top-nav__buttons a:hover {
  transform: scale(1.1);
  transition: transform 0.05s ease-in-out;
}

@media screen and (min-width: 1440px) {
  .nav-wrapper {
    width: 1440px;
  }
}
@media screen and (max-width: 600px) {
  #logo {
    width: 25rem;
  }
}
@media screen and (max-width: 400px) {
  h1 {
    margin: 0 0 0 1rem;
  }
  #logo {
    width: 20rem;
  }
}
.menu {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  margin: 3rem;
}

#menu__exit {
  align-self: flex-end;
}

.menu__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu__button {
  margin: 1rem;
}

.portfolio {
  margin: 12rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.portfolio .portfolio__projects {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 4rem 4rem;
  text-align: center;
}

article {
  margin: 3rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.15s ease-in-out;
  border-radius: 4px;
}

.card__front {
  border: none;
  width: 52.4rem;
  height: 47.4rem;
}

.card__back {
  border: 1.2rem #8eba9e solid;
  width: 50rem;
  height: 45rem;
}
.card__back .card__title {
  font-family: "Glacial Indifference", sans-serif;
  font-size: 2rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-top: 2.5rem;
}
.card__back .card__tagline {
  font-family: "Inter", sans-serif;
  color: #424242;
  font-size: 2rem;
  font-weight: 300;
  width: 80%;
  margin: 0;
}
.card__back .tech {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 2rem;
}
.card__back .tech__icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 9rem;
  margin-top: 1rem;
}
.card__back .tech__icon {
  height: 3.5rem;
  margin: 0.5rem;
}
.card__back .tech__label {
  font-family: "Glacial Indifference", sans-serif;
  font-size: 2rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1rem;
}
.card__back .project__buttons-container {
  position: absolute;
  bottom: 3rem;
  width: 95%;
  display: flex;
  justify-content: space-evenly;
}

.card__button {
  width: 12rem;
  height: 4rem;
  font-size: 1.8rem;
}

.card__back:hover,
.card__front:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: transform 0.15s ease-in-out;
}

@media screen and (max-width: 1200px) {
  .card__front {
    width: 45.4rem;
    height: 40.4rem;
  }
  .card__back {
    width: 43rem;
    height: 38rem;
  }
  .card__back .card__tagline {
    font-size: 1.6rem;
  }
  .card__back .tech {
    width: 85%;
  }
  .card__back .tech__icon {
    height: 2.8rem;
  }
}
@media screen and (max-width: 550px) {
  .card__front {
    width: 40rem;
    height: 45rem;
  }
  .card__back {
    width: 36rem;
    height: 40rem;
  }
  .card__back .card__tagline {
    width: 85%;
  }
  .card__back .tech {
    width: 95%;
  }
  .card__back .tech__icon {
    height: 2.5rem;
  }
  .card__back .project__buttons-container {
    bottom: 1rem;
    width: 95%;
  }
  .card__button {
    width: 11rem;
    height: 3rem;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 430px) {
  .card__front {
    height: 48rem;
  }
  .card__back {
    height: 43rem;
  }
  .project__buttons-container {
    flex-wrap: wrap;
  }
  .project__buttons-container button {
    margin: 0.5rem 0;
  }
}
.footer {
  background-color: #424242;
  height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}

.footer__social {
  width: 3rem;
  margin: 1.5rem;
}

.footer__links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__link {
  font-family: "Glacial Indifference", sans-serif;
  color: #424242;
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 200;
  letter-spacing: 0.5rem;
  color: #F5F5F5;
  text-decoration: none;
  font-size: 1.5rem;
  margin: 2rem 4rem;
}

#footer__logo {
  width: 15rem;
  margin: 1.5rem;
}

@media screen and (max-width: 700px) {
  .footer {
    height: 30rem;
  }
  .footer__links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer__link {
    margin: 0.5rem;
  }
}
.redirect {
  color: #47725d;
  font-family: "Inter", sans-serif;
  color: #424242;
  font-size: 2rem;
  font-weight: 300;
  margin: 3rem;
  text-decoration: none;
}

.redirect:hover {
  text-decoration: underline;
  text-decoration-color: #47725d;
}

