@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;

.top-nav {
  background-color: $primary-background-color;
  border-top: 5px solid $secondary-accent-color;
  min-width: 100%;
  display: flex;
  justify-content: center;
}

.top-nav__wrapper {
  @include space-between;
  width: 100%;
}

#logo {
  margin: 1rem 0 0 2rem;
  width: 29rem;
}

.top-nav__buttons {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 2rem;
  a {
    @include button-text;
    padding-left: 3rem;
    color: $emphasis-text-color;
    transition: transform 0.05s ease-in-out;
  }
  a,
  a:active {
    text-decoration: none;
  }
  a:hover {
    @include link-hover;
  }
}

@media screen and (min-width: 1440px) {
  .nav-wrapper {
    width: 1440px
  }
}

@media screen and (max-width: 600px) {
  #logo {
    width: 25rem;
  }
}

@media screen and (max-width: 400px) {
  h1 {
    margin: 0 0 0 1rem;
  }
  #logo {
    width: 20rem;
  }
}

