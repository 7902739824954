@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;

.contact {
  @include center-column;
  margin: 12rem 0 0;
  .contact__email-container {
    display: flex;
    align-items: center;
    margin: 2rem 0 2em;
  }
  .contact__email {
    color: $primary-accent-color;
    margin-left: 1rem;
  }
  .contact__email:hover {
    @include inline-link-hover;
  }
  .contact__socials-container {
    @include space-evenly;
    align-items: center;
    height: 10rem;
    width: 30%;
  }
  .contact__social {
    width: 5rem;
    transition: transform 0.05s ease-in-out;
  }
  img:hover{
    @include link-hover;
  }
}

@media screen and (max-width: 650px) {
  .contact {
    .contact__socials-container {
      width: 60%;
    }
  }
}

@media screen and (max-width: 450px) {
  .contact__email-container {
    @include center-column;
  }
}
